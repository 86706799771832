<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" :url="apis.erpCurrencyProfileItems" :baseFilters="{currencyProfileId: profileSelected.id}" :height="window.height - 310" tableSize="sm" :columns="columns" v-loading="isLoading" @edit="openModalForEdit">
          <template #title>
            <!-- Profile -->
            <b-dropdown :text="profileSelected.id === -1 ? 'All Profiles' : profileSelected.name" class="dropdown" variant="secondary">
              <!-- <b-dropdown-item @click="setProfileFilter(null)">{{$t('All Profiles')}}</b-dropdown-item> -->
              <b-dropdown-item v-for="item in profileList" :key="item.id" :active="profileSelected === item.id" @click="setProfileFilter(item)" style="width: auto;">
                <div>
                  {{ item.name }}
                  <el-link icon="el-icon-delete" @click="deleteProfile(item)" v-if="getErpCan('CurrencyProfile.DeleteProfile')"></el-link>
                </div>
              </b-dropdown-item>
            </b-dropdown>

            <b-button variant="secondary" class="ml-50" @click="openModalForCreateProfile" v-if="getErpCan('CurrencyProfile.CreateProfile')">
              <feather-icon icon="PlusIcon"/>
              Create Profile
            </b-button>
          </template>
          <template #button>
            <b-button variant="info" class="mr-1" @click="openModalForCreate" v-if="getErpCan('CurrencyProfile.AddItem')">
              <feather-icon icon="PlusIcon"/>
              Add Item
            </b-button>

            <b-button variant="info" class="mr-1" @click="openModalForUpload" v-if="getErpCan('CurrencyProfile.UploadItems')">
              <feather-icon icon="UploadIcon"/>
              Upload Items
            </b-button>
          </template>
          <template #refresh_button>
            <b-button variant="secondary" @click="loadList()" class="mr-1 btn-icon">
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
          </template>

          <template #cell(effectiveTime)="row">
            <span :class="isTimePassed(row.item.effectiveTime) ? 'text-green' : 'text-orange'">{{ formatWithCurrentTimezone(row.item.effectiveTime) }}</span>
          </template>

          <template #cell(uploadBy)="row">
            <el-tooltip class="item" effect="dark" :content="row.item.hash" placement="top">
              <i class="el-icon-upload" v-if="row.item.hash"></i>
            </el-tooltip>
            {{ row.item.uploadBy }}
          </template>
          <template #cell(actions)="row">
            <el-link icon="el-icon-edit" @click="openModalForEdit(row.item)" v-if="getErpCan('CurrencyProfile.EditItem')">{{$t('Edit')}}</el-link>
            <el-link icon="el-icon-delete" @click="openModalForDelete(row.item)" v-if="getErpCan('CurrencyProfile.DeleteItem')">{{$t('Delete')}}</el-link>
            <el-link icon="el-icon-search" @click="showAudits(row.item)" v-if="getErpCan('CurrencyProfile.Audits')">{{$t('Audits')}}</el-link>
          </template>
        </AdvanceTable>
    </section>

    <el-dialog
      class="compact"
      :title="$t(title)"
      :visible.sync="isShow"
    >
      <b-row>
        <b-col lg="12">
          <b-form-group :label="$t('Profile')">
            <el-select v-model="row.currencyProfileId" class="w-100">
              <el-option v-for="item in profileList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group :label="$t('Base Currency')">
            <el-input v-model="row.baseCurrency"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group :label="$t('Quote Currency')">
            <el-input v-model="row.quoteCurrency"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group :label="$t('Exchange Rate')">
            <el-input v-model="row.exchangeRate" type="number" min="0" max="99999999.9999999999"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group :label="$t('Billing Period')">
            <el-input v-model="row.billingPeriod" type="input"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group :label="$t('Account Period')">
            <el-input v-model="row.accountPeriod" type="input"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group :label="$t('Effective Time')">
            <el-date-picker v-model="row.effectiveTime" value-format="yyyy-MM-dd HH:mm:ss"  type="datetime"/>
            {{ getCurrentTimezoneName() }} ({{ row.timezone }})
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="action === 'EDIT'">
        <b-col lg="12">
          <b-form-group :label="$t('Upload By')">
            <el-input v-model="row.uploadBy" type="input" disabled="disabled"/>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="getErpCan('CurrencyProfile.AddItem')">
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="info" class="btn mr-2" @click="isShow=false;$refs.advanceTable.loadList()">
            {{ $t('Cancel') }}
          </b-button>
          <b-button variant="primary" class="btn" @click="save()">
            {{ $t('Save') }}
          </b-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      class="compact"
      :title="$t('Create Profile')"
      :visible.sync="isShowProfile"
    >
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col lg="12">
              <b-form-group :label="$t('Name')">
                <el-input v-model="profile.name"/>
              </b-form-group>
            </b-col>
          </b-row>
          <div>
            <div class="w-100 d-flex justify-content-end">
              <b-button variant="info" class="btn mr-2" @click="isShowProfile = false;">
                {{ $t('Cancel') }}
              </b-button>
              <b-button variant="primary" class="btn" @click="saveProfile();">
                {{ $t('Save') }}
              </b-button>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </el-dialog>

    <UploadModal ref="uploadView">
      <template v-slot:tip="scope">
        Only accepts xlsx files with size less than {{ $refs.uploadView ? $refs.uploadView.getMaxSizeForDisplay() : '' }}<br/>
        <a href="https://prod-kec-dashboard.s3.ap-east-1.amazonaws.com/public/Currency-Upload-Template.xlsx" target="_blank" @click.stop>Download Template</a>
      </template>
    </UploadModal>

    <ExcelResultModal ref="excelResult"/>
    <AuditTrailModal ref="auditTrail"/>
  </div>
</template>

<script>
import {
  getAccessToken, getErpCan,
  getErpProfile
} from "@/utils/account-localstorage";
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@/configs/apis";
import { BButton, BModal } from "bootstrap-vue";
import UploadModal from "@/views/erp/components/UploadModal.vue";
import {
  formatWithCurrentTimezone,
  getCurrentTimezone,
  getCurrentTimezoneName,
  isTimePassed,
  utcTimeToLocal
} from "./utils/time";
import Prompt from "@/views/erp/mixins/Prompt";
import ExcelResultModal from "@/views/erp/components/ExcelResultModal.vue";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";
import axios from "axios";

export default {
  mixins: [Prompt],
  components: {
    AuditTrailModal,
    ExcelResultModal,
    UploadModal,
    BButton,
    BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "baseCurrency", modelName: "baseCurrency", label: "Base Currency", width: "150", filtertype: "input", sortable: true },
        { key: "quoteCurrency", modelName: "quoteCurrency", label: "Quote Currency", width: "150", filtertype: "input", sortable: true },
        { key: "exchangeRate", modelName: "exchangeRate", label: "Exchange Rate", width: "150", sortable: true },
        { key: "effectiveTime", modelName: "effectiveTime", label: "Effective Time", width: "250", sortable: true },
        { key: "billingPeriod", modelName: "billingPeriod", label: "Billing Period", filtertype: "input", sortable: true },
        { key: "accountPeriod", modelName: "accountPeriod", label: "Account Period", filtertype: "input", sortable: true },
        { key: "uploadBy", modelName: "uploadBy", label: "Upload By", width: "250", filtertype: "input", sortable: true },
        { key: "actions", modelName: "actions", label: "Actions", sortable: false, width: "240" },
      ],
      profileSelected: {
        id: -1
      },
      profileList: [],
      itemList: [],

      action: null,
      isShow: false,
      isShowProfile: false,
      title: '',
      row: {
      },
      profile: {},
      isLoading: false
    };
  },
  computed: {
    apis() {
      return apis
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadCurrencyProfile();
  },
  methods: {
    getErpCan,
    getCurrentTimezoneName,
    isTimePassed,
    getCurrentTimezone,
    formatWithCurrentTimezone,
    getAccessToken,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openModalForView(item) {
      console.log(item);
      this.$alert(item.remark, 'Remark: ' + item.code);
    },
    openModalForCreate() {
      this.isShow = true
      this.action = 'CREATE'
      this.title = 'Add Currency Profile'

      this.row = {timezone: getCurrentTimezone(), currencyProfileId: this.profileSelected};
    },
    openModalForEdit(item) {
      this.row = _.clone(item)
      this.row.timezone = getCurrentTimezone();
      this.row.effectiveTime = utcTimeToLocal(this.row.effectiveTime);

      this.isShow = true
      this.action = 'EDIT'
      this.title = 'Edit Currency Profile'
    },
    openModalForDelete(item) {
      this.$confirm('Confirm delete this item?', 'Delete Item: ' + item.id).then(() => {
        const response = axios.delete(
          apis.erpCurrencyProfileItems + "/" + item.id,
        ).then(response => {
          this.loadList();
          this.promptInfo("Item Deleted");
        }).catch(e => {
          this.promptError(e);
        })
      }).catch(() => {});
    },
    openModalForUpload() {
      this.$refs.uploadView.showUpload('Upload Currency Profile: ' + this.profileSelected.name, 'CURRENCY_PROFILE', {maxSize: 10485760, allowedExtension: 'xlsx'}, (form) => {
        this.isLoading = true;

        form.currencyProfileId = this.profileSelected.id;
        form.timezone = getCurrentTimezone();

        axios.post(
          apis.erpProcessCurrencyProfileItems,
          form
        ).then((response) => {
          this.loadList();
          this.$refs.excelResult.showResult(response.data.data);
        }).catch(e => {
          console.log(e);
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },
    openModalForCreateProfile() {
      this.isShowProfile = true
      this.action = 'CREATE'
    },
    deleteProfile(row) {
      this.$confirm('Only unused Profile can be deleted. All rows belong to it will be deleted.', 'Delete Profile: ' + row.name).then(() => {
        axios.delete(
          apis.erpCurrencyProfiles + "/" + row.id,
        ).then(() => {
          this.promptInfo("Profile Deleted");
          this.loadCurrencyProfile();
        });
      }).catch(() => {});
    },
    async loadCurrencyProfile() {
      const response = await axios.get(
        apis.erpCurrencyProfiles,
      );

      this.profileList = response.data.data.data;

      if (this.profileList.length > 0) {
        await this.setProfileFilter(this.profileList[0]);
      }
    },
    async setProfileFilter(profile) {
      this.profileSelected = profile;

      await this.loadList();
    },

    async loadList() {
      if (!this.$refs.advanceTable) return; //??

      this.$nextTick(() => {
        this.$refs.advanceTable.loadList();
      })

    },

    async saveProfile() {
      if (this.action === "CREATE") {
        try {
          const response = await axios.post(
            apis.erpCurrencyProfiles,
            {
              "name": this.profile.name
            }
          );
          await this.loadList();
          this.isShow = false;
          this.promptInfo("Create Currency Profile Success");
        } catch (e) {
          this.promptError(e);
        } finally {
          await this.loadCurrencyProfile();
          this.isShowProfile = false;
        }
      } else {
        try {
          const response = await axios.put(
            apis.erpCurrencyProfiles + "/" + this.row.id,
            {
              "name": this.profile.name
            }
          );
          await this.loadList();
          this.isShow = false;
          this.promptInfo("Update Currency Profile Success");
        } catch (e) {
          this.promptError(e);
        } finally {
          await this.loadCurrencyProfile();
          this.isShowProfile = false;
        }
      }
    },
    async save() {
      if (this.action === "CREATE") {
        try {
          const response = await axios.post(
            apis.erpCurrencyProfileItems,
            this.row
          );
          await this.loadList();
          this.isShow = false;

          this.promptInfo("Create Currency Profile Item Success");
        } catch (e) {
          this.promptError(e);
        }
      } else {
        try {
          const response = await axios.put(
            apis.erpCurrencyProfileItems + "/" + this.row.id,
            this.row
          );
          await this.loadList();
          this.isShow = false;

          this.promptInfo("Update Currency Profile Item Success");
        } catch (e) {
          this.promptError(e);
        }
      }
    },

    showAudits(entity) {
      this.$refs.auditTrail.show({objectId: entity.id, className: 'com.kerrylogistics.dashboard.entities.erp.CurrencyProfileItem', name: 'Currency Profile Item'});
    }
  }
};
</script>

<style scoped>
.text-green {
  color: green
}

.text-orange {
  color: orange;
}
</style>
